import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueNativeSock from "vue-native-websocket-vue3";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "./assets/fonts.css";

var app = createApp(App);
app.use(store);

// Call the action once during app initialization
// app.mixin({
//   created() {
//   },
// });

const host = window.location.hostname;
const prot = window.location.protocol === "http:" ? "ws" : "wss";
const djport = process.env.VUE_APP_DJANGO_PORT
  ? ":" + process.env.VUE_APP_DJANGO_PORT
  : "";

app.config.globalProperties.$djport = djport;

app.use(VueNativeSock, `${prot}://${host}${djport}/ws/altitude/`, {
  store: store,
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  reconnectionAttempts: 20, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 500,
});
app.use(router);
app.use(ElementPlus);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.mount("#app");

export default app;
