import { createStore } from "vuex";
import Cookies from "js-cookie";

// import main from "../main";

// Helper function to retrieve blurb by name
function getBlurbByName(state, name) {
  const blurb = state.blurbs[name];
  if (blurb) {
    return blurb;
  } else if (state.socket.socket) {
    // Queue the Blurb if not already queued
    if (!state.socket.blurbQueue.includes(name)) {
      store.commit("pushBlurbQueue", name);
    }
  }
  return "Loading..."; // or any other default value you want
}

const store = createStore({
  state: {
    messages: [],
    socket: {
      socket: null,
      // Connection Status
      isConnected: false,
      // Reconnect error
      reconnectError: false,
      // Heartbeat message sending time
      heartBeatInterval: 50000,
      // Heartbeat timer
      heartBeatTimer: 0,
      blurbQueue: [],
      blurbQueueTimer: null,
      blurbQueueInterval: 500,
    },
    auth: {
      is_auth: false,
      username: "",
    },
    teams: {
      selectedTeam: "1",
      all: {
        1: {
          name: "",
          time_sheep: 0,
          time_alpaca: 0,
          time_llama: 0,
          time_setup: 0,
          time_setup_many_sheep: 0,
          many_sheep_thres: 0,
          time_teardown: 0,
          time_extra_driving: 0,
          begin_time: "07:00:00",
          max_day_time: 0,
        },
      },
    },
    filters: {
      fromDate: Cookies.get("fromDate") || null,
      toDate: Cookies.get("toDate") || null,
      selectedScheduleType: "scheduled",
      seasonId: 1,
      selectedSchedules: [],
      selectedSeason: null,
      searchStr: "",
    },
    blurbs: {},
    scheduling: {
      displaySchedule: {
        scheduled_start: "1970-01-01T00:00:00",
      },
      schedules: [],
      route: [],
    },
    seasons: {},
  },
  getters: {
    getBlurbByName: (state) => (name) => getBlurbByName(state, name),
    scheduleRequestData: (state) => {
      return {
        type: state.filters.selectedScheduleType,
        team: state.teams.selectedTeam,
        season: state.filters.selectedSeason,
        from_date: state.filters.fromDate,
        to_date: state.filters.toDate,
      };
    },
  },
  mutations: {
    // Connection open
    addMessage(state, message) {
      state.messages.push(message);
    },
    setBlurbTexts(state, blurbs) {
      state.blurbs = blurbs;
    },
    pushBlurbQueue(state, name) {
      state.socket.blurbQueue.push(name);
    },
    setSelectedTeam(state, newValue) {
      state.teams.selectedTeam = newValue;
    },
    updateFilters(state, filters) {
      state.filters.fromDate = filters.fromDate;
      state.filters.toDate = filters.toDate;
      state.filters.selectedScheduleType = filters.selectedScheduleType;
      state.filters.selectedSeason = filters.selectedSeason;
    },
    updateSearchStr(state, searchStr) {
      state.filters.searchStr = searchStr;
    },
    updateSelectedSchedules(state, schedules) {
      state.filters.selectedSchedules = schedules;
    },
    SOCKET_ONOPEN(state, event) {
      state.socket.socket = event.currentTarget;
      state.socket.isConnected = true;
      // When the connection is successful, start sending heartbeat messages regularly to avoid being disconnected by the server
      state.socket.heartBeatTimer = setInterval(() => {
        const message = "Heartbeat message";
        state.socket.isConnected &&
          state.socket.socket.send(
            JSON.stringify({
              jsonrpc: "2.0",
              method: "heartbeat",
              params: { message: message },
            })
          );
      }, state.socket.heartBeatInterval);

      // Start the Blurb Queue timer
      state.socket.blurbQueueTimer = setInterval(() => {
        if (state.socket.blurbQueue.length > 0) {
          while (state.socket.blurbQueue.length > 0) {
            const item = state.socket.blurbQueue.pop();
            const method = "sendJSONrpcMessage";
            const params = { method: "request_blurb", params: { name: item } };
            store.dispatch(method, params);
          }
        }
      }, state.socket.blurbQueueInterval);

      // Get all existing blurbs from server
      store.dispatch("sendJSONrpcMessage", {
        method: "request_all_blurbs",
        params: {},
      });

      // Get Authentication info from server
      store.dispatch("sendJSONrpcMessage", {
        method: "request_auth_info",
        params: {},
      });

      // Get global config info from server
      // Get all existing blurbs from server
      store.dispatch("sendJSONrpcMessage", {
        method: "request_global_config",
        params: {},
      });
    },
    // Connection closed
    SOCKET_ONCLOSE(state, event) {
      state.socket.isConnected = false;
      // Stop the heartbeat message when the connection is closed
      clearInterval(state.socket.heartBeatTimer);
      state.socket.heartBeatTimer = 0;
      console.log("The line is disconnected: " + new Date());
      console.log(event);
    },
    // An error occurred
    SOCKET_ONERROR(state, event) {
      console.error(state, event);
    },
    // Receive the message sent by the server
    SOCKET_ONMESSAGE(state, message) {
      console.log(message.data);
      var data = {};
      try {
        data = JSON.parse(message.data);
      } catch {
        console.log("Invalid JSON");
        return;
      }
      if (data.method == "return_all_blurbs") {
        this.dispatch("updateBlurbTexts", data.result.blurbs);
      }
      if (data.method == "return_auth_info") {
        state.auth.username = data.result.username;
        state.auth.is_auth = data.result.is_authenticated;
        const maxAgeSeconds = 30 * 24 * 60 * 60;
        // Set the cookie with the expiration date
        document.cookie = `sessionid=${data.result.sessionid}; max-age=${maxAgeSeconds}; path=/`;
      }
      // Handle return_schedule_data response
      if (data.method == "return_schedule_data") {
        const schedules = data.result.schedules;
        state.scheduling.schedules = schedules;
        state.scheduling.route = data.result.route;
        console.log(data);
        if ("change_type" in data.result) {
          state.filters.selectedScheduleType = data.result.change_type;
        }
      }
      if (data.method == "return_schedule_info") {
        const displaySchedule = data.result.schedule;
        state.scheduling.displaySchedule = displaySchedule;
      }
      if (data.method == "return_team_data") {
        state.teams.all = data.result.all;
      }
      if (data.method == "return_global_config") {
        state.seasons = data.result.seasons;
      }
    },
    // Auto reconnect
    SOCKET_RECONNECT(state, count) {
      console.info("Message system reconnecting...", state, count);
    },
    // Reconnect error
    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true;
    },
  },
  actions: {
    cancelSchedules(context) {
      const params = { ...context.getters.scheduleRequestData };
      params.ids = context.state.filters.selectedSchedules;
      context.dispatch("sendJSONrpcMessage", {
        method: "cancel_schedules",
        params: params,
      });
    },
    fetchScheduleInfo(context, uuid) {
      context.dispatch("sendJSONrpcMessage", {
        method: "get_schedule_info",
        params: {
          uuid: uuid,
        },
      });
    },
    fetchSchedules(context) {
      context.dispatch("sendJSONrpcMessage", {
        method: "get_schedule_data",
        params: context.getters.scheduleRequestData,
      });
    },
    mailSchedules(context, { name, schedule_id }) {
      const params = { ...context.getters.scheduleRequestData };
      params.name = name;
      if (typeof schedule_id !== "undefined") {
        params.ids = [schedule_id];
      } else {
        params.ids = context.state.filters.selectedSchedules;
      }
      context.dispatch("sendJSONrpcMessage", {
        method: "mail_schedules",
        params: params,
      });
    },
    moveScheduleDay(context, delta) {
      const params = { ...context.getters.scheduleRequestData };
      params.ids = context.state.filters.selectedSchedules;
      params.delta = delta;
      context.dispatch("sendJSONrpcMessage", {
        method: "move_schedule_day",
        params: params,
      });
    },
    moveScheduleOrder(context, delta) {
      const params = { ...context.getters.scheduleRequestData };
      params.id = context.state.filters.selectedSchedules[0];
      params.delta = delta;
      context.dispatch("sendJSONrpcMessage", {
        method: "move_schedule_order",
        params: params,
      });
    },
    sendJSONrpcMessage({ state }, { method, params }) {
      const socket = state.socket.socket;
      if (socket) {
        socket.send(
          JSON.stringify({
            jsonrpc: "2.0",
            id: 1,
            method: method,
            params: params,
          })
        );
      }
    },
    setDateTime(context, { date, time }) {
      const params = { ...context.getters.scheduleRequestData };
      params.ids = context.state.filters.selectedSchedules;
      params.set_date = date;
      params.set_time = time;
      context.dispatch("sendJSONrpcMessage", {
        method: "set_datetime",
        params: params,
      });
    },
    uncancelSchedules(context) {
      const params = { ...context.getters.scheduleRequestData };
      params.ids = context.state.filters.selectedSchedules;
      context.dispatch("sendJSONrpcMessage", {
        method: "uncancel_schedules",
        params: params,
      });
    },
    unscheduleSchedules(context) {
      const params = { ...context.getters.scheduleRequestData };
      params.ids = context.state.filters.selectedSchedules;
      context.dispatch("sendJSONrpcMessage", {
        method: "unschedule_schedules",
        params: params,
      });
    },
    updateBlurbTexts({ commit }, blurbs) {
      commit("setBlurbTexts", blurbs);
    },
    saveBlurbByName(context, { name, value }) {
      const params = {
        name: name,
        value: value,
      };

      // Assuming you have a "sendJSONrpcMessage" action defined in your store
      return context.dispatch("sendJSONrpcMessage", {
        method: "save_blurb",
        params,
      });
    },
    sendLogin(context, { username, password }) {
      context.dispatch("sendJSONrpcMessage", {
        method: "login",
        params: { username: username, password: password },
      });
    },
    sendLogout(context) {
      context.dispatch("sendJSONrpcMessage", {
        method: "logout",
        params: {},
      });
    },
    requestBlurb({ commit }, name) {
      commit("pushBlurbQueue", name);
    },
    fetchTeamData(context) {
      context.dispatch("sendJSONrpcMessage", {
        method: "get_team_data",
        params: {},
      });
    },
    sendTeamUpdate(context, teamData) {
      context.dispatch("sendJSONrpcMessage", {
        method: "update_team",
        params: { team_data: teamData },
      });
    },
    addTeam(context, name) {
      context.dispatch("sendJSONrpcMessage", {
        method: "add_team",
        params: { name: name },
      });
    },
    autoSchedule(context, { fromDate, fromTime, includeScheduled, toDate }) {
      const params = { ...context.getters.scheduleRequestData };
      params.ids = context.state.filters.selectedSchedules;
      params.fromDate = fromDate;
      params.fromTime = fromTime;
      params.includeScheduled = includeScheduled;
      params.toDate = toDate;
      context.dispatch("sendJSONrpcMessage", {
        method: "auto_schedule",
        params: params,
      });
    },
  },
  modules: {},
});

export default store;
