import { createRouter, createWebHistory } from "vue-router";
// import store from "../store";

const routes = [
  {
    path: "/",
    component: () =>
      import(
        /* webpackChunkName: "headingTemplate" */ "../components/HeadingTemplate.vue"
      ),
    children: [
      {
        path: "",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
      },
    ],
  },
  {
    path: "/crew",
    component: () =>
      import(
        /* webpackChunkName: "headingTemplate" */ "../components/HeadingTemplate.vue"
      ),
    children: [
      {
        path: "",
        name: "Our Crew",
        component: () =>
          import(/* webpackChunkName: "crew" */ "../views/CrewView.vue"),
      },
    ],
  },
  {
    path: "/contact",
    component: () =>
      import(
        /* webpackChunkName: "headingTemplate" */ "../components/HeadingTemplate.vue"
      ),
    children: [
      {
        path: "",
        name: "Contact Us",
        component: () =>
          import(/* webpackChunkName: "contact" */ "../views/ContactView.vue"),
      },
    ],
  },
  {
    path: "/services",
    component: () =>
      import(
        /* webpackChunkName: "headingTemplate" */ "../components/HeadingTemplate.vue"
      ),
    children: [
      {
        path: "",
        name: "Services",
        component: () =>
          import(
            /* webpackChunkName: "services" */ "../views/ServicesView.vue"
          ),
      },
    ],
  },
  {
    path: "/register",
    component: () =>
      import(
        /* webpackChunkName: "headingTemplate" */ "../components/HeadingTemplate.vue"
      ),
    children: [
      {
        path: "",
        name: "Register",
        component: () =>
          import(
            /* webpackChunkName: "register" */ "../views/RegisterView.vue"
          ),
      },
    ],
  },
  {
    path: "/reviews",
    component: () =>
      import(
        /* webpackChunkName: "headingTemplate" */ "../components/HeadingTemplate.vue"
      ),
    children: [
      {
        path: "",
        name: "Reviews",
        component: () =>
          import(/* webpackChunkName: "reviews" */ "../views/ReviewsView.vue"),
      },
    ],
  },
  {
    path: "/admin",
    component: () =>
      import(
        /* webpackChunkName: "headingTemplate" */ "../components/HeadingTemplate.vue"
      ),
    children: [
      {
        name: "Admin",
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "reviews" */ "../views/AdminLoginView.vue"
          ),
      },
    ],
  },
  {
    path: "/admin_scheduling",
    component: () =>
      import(
        /* webpackChunkName: "headingTemplate" */ "../components/NoHeadingTemplate.vue"
      ),
    children: [
      {
        name: "AdminScheduling",
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "reviews" */ "../views/AdminSchedulingView.vue"
          ),
      },
    ],
    meta: { requiresAuth: true },
  },
  {
    path: "/customer/:uuid",
    component: () =>
      import(
        /* webpackChunkName: "headingTemplate" */ "../components/HeadingTemplate.vue"
      ),
    children: [
      {
        name: "Customer",
        path: "",
        component: () =>
          import(/* webpackChunkName: "reviews" */ "../views/CustomerView.vue"),
      },
    ],
    meta: { requiresAuth: false },
  },
  {
    path: "/:catchAll(.*)",
    component: () =>
      import(
        /* webpackChunkName: "headingTemplate" */ "../components/HeadingTemplate.vue"
      ),
    children: [
      {
        path: "",
        name: "NotFound",
        component: () =>
          import(/* webpackChunkName: "reviews" */ "../views/NotFound.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  document.title = `Altitude Shearing - ${to.name}`;
  // if (to.matched.some((record) => record.meta.requiresAuth)) {
  //   // Check if the user is authenticated
  //   if (!store.state.auth.is_auth) {
  //     // Redirect to the login page or any other appropriate route
  //     next("/admin/");
  //   } else {
  //     // Continue to the route
  //     next();
  //   }
  // } else {
  //   // Continue to the route
  //   next();
  // }
  next();
});

export default router;
