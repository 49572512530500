<template>
  <div class="app">
    <router-view class="router-view" />
  </div>
</template>
<script></script>
<style>
html {
  height: 100%;
}
body {
  height: 100%;
  margin: 0px;
}
#app {
  height: 100%;
}
.app {
  height: 100%;
}
</style>
